.main_logo{
    width: 130px;
    padding: 7px 0px 0px 0px;
}
.pro_pic{
    object-fit: cover;
    height: 33px;
    width: 33px;
    border-radius: 50px;
}
.bg-light-gray {
    background: #e9e9e9;
}
ion-fab-button{
    --background: #cb3737;
}
.tt-card {
    padding-left: 8px;
    padding-right: 8px;
    background-color: #00365f;
    color: #fff;
}
.attest-button {
    border: 1px solid #00365f;
    border-radius: 10px;
    padding: 1px 5px 2px 5px;
    color: #cb3737;
    margin-left: 5px;
}
.nsp-card {
    padding-left: 8px;
    padding-right: 8px;
    background-color: #00365fc7;
    color: #fff;
}
.social-options {
    position: absolute;
    right: 0;
    font-size: 20px;
} 
.social-name {
    margin-left: 10px;
}
.text-align-right {
    text-align: right;
}
.text-align-left {
    text-align: left;
}
.s-13{
    font-size: 13px;
}
.s-15{
    font-size: 15px;
}
.black{
    color: black;
}
.pb-10 {
    padding-bottom: 10px;
}
.pad-10{
    padding: 10px 10px 10px 10px;
}
.pad-5{
    padding: 5px 5px 5px 5px;
}
.pro-credits{
    margin-top: 12%;
}
.pro-button{
    height: 30px;
    width: 100%;
}
.pro-shop-icon{
    width: 20px;
}
.range-left {
    margin-right: -1px;
    padding: 0px 0px 3px 0px;
}
.range-right {
    margin-left: -1px;
    padding: 0px 0px 3px 0px;
}
.pro-shop-cards {
    margin: 3px;
    /*border: 1px solid #00365f;*/
    padding: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 15px;
}
.noticebaord-cards {
    margin: 5px 10px;
    /*border: 1px solid #00365f;*/
    padding: 10px;
}
.tour-cards {
    margin: 5px;
    /* border: 1px solid #00365f; */
    padding: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 15px;
    height: 125px;
}
.pro-shop-pic {
    object-fit: contain;
    height: 100px;
    width: 100px;
    border-radius: 0px;
}
.tours-pic {
    object-fit: contain;
    height: 80px;
    width: 80px;
    border-radius: 0px;
}
.justify-content-space-between {
    justify-content: space-between;
}
.justify-content-space-evenly {
    justify-content: space-evenly;
}
.new-segment{
    background: rgb(221 221 221 / 54%);
    border-radius: 20px;
}    
@media screen and (max-width: 700px){
    .new-segment-button{
        --border-radius:40px;
        padding: 1px;
    }    
    .new-segment-button.segment-button-checked .new-segment-button-label{    
        background-color: #00365f;
        width: 115%;
        border-radius: 40px;
        height: 28px;
        color: white;
    }
    .new-segment-button .new-segment-button-label{
        background-color: transparent;
        width: 115%;
        border-radius: 40px;
        height: 28px;    
        color: #00365f;
        border: 2px solid transparent;  
    }
    .new-segment-button-dark{
        --border-radius:40px;
    }    
    .new-segment-button-dark.segment-button-checked .new-segment-button-label-dark{    
        background-color: white;
        width: 115%;
        border-radius: 40px;
        height: 28px;
        color: #00365f;
    }
    .new-segment-button-dark .new-segment-button-label-dark{
        background-color: #222428;
        width: 115%;
        border-radius: 40px;
        height: 28px;    
        color: white;
        border: 2px solid white;  
    }
    .league-cards {
        margin: 7px 10px 7px 10px;
      }
}
@media screen and (min-width: 700px){
    .new-segment-button{
        --border-radius:40px;
        padding: 1px;
    }    
    .new-segment-button.segment-button-checked .new-segment-button-label{    
        background-color: #00365f;
        width: 106%;
        border-radius: 40px;
        height: 28px;
        color: white;
    }
    .new-segment-button .new-segment-button-label{
        background-color: transparent;
        width: 106%;
        border-radius: 40px;
        height: 28px;    
        color: #00365f;
        border: 2px solid transparent;  
    }
    .new-segment-button-dark{
        --border-radius:40px;
    }    
    .new-segment-button-dark.segment-button-checked .new-segment-button-label-dark{    
        background-color: white;
        width: 106%;
        border-radius: 40px;
        height: 28px;
        color: #00365f;
    }
    .new-segment-button-dark .new-segment-button-label-dark{
        background-color: #222428;
        width: 106%;
        border-radius: 40px;
        height: 28px;    
        color: white;
        border: 2px solid white;  
    }  
    .league-cards {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        margin-bottom: 7px;
    }  
}
@media screen and (min-width: 900px){
    .new-segment-button{
        --border-radius:40px;
        padding: 1px;
    }
    .new-segment-button.segment-button-checked .new-segment-button-label{    
        background-color: #00365f;
        width: 105%;
        border-radius: 40px;
        height: 28px;
        color: white;
    }
    .new-segment-button .new-segment-button-label{
        background-color: transparent;
        width: 105%;
        border-radius: 40px;
        height: 28px;    
        color: #00365f;
        border: 2px solid transparent;  
    }
    .new-segment-button-dark{
        --border-radius:40px;
    }
    .new-segment-button-dark.segment-button-checked .new-segment-button-label-dark{    
        background-color: white;
        width: 105%;
        border-radius: 40px;
        height: 28px;
        color: #00365f;
    }
    .new-segment-button-dark .new-segment-button-label-dark{
        background-color: #222428;
        width: 105%;
        border-radius: 40px;
        height: 28px;    
        color: white;
        border: 2px solid white;  
    }
    .league-cards {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        margin-bottom: 7px;
    }  

}
@media screen and (min-width: 1300px){
    .new-segment-button{
        --border-radius:40px;
        padding: 1px;
    }
    .new-segment-button.segment-button-checked .new-segment-button-label{    
        background-color: #00365f;
        width: 103%;
        border-radius: 40px;
        height: 28px;
        color: white;
    }
    .new-segment-button .new-segment-button-label{
        background-color: transparent;
        width: 103%;
        border-radius: 40px;
        height: 28px;    
        color: #00365f;
        border: 2px solid transparent;  
    }
    .new-segment-button-dark{
        --border-radius:40px;
    }
    .new-segment-button-dark.segment-button-checked .new-segment-button-label-dark{    
        background-color: white;
        width: 103%;
        border-radius: 40px;
        height: 28px;
        color: #00365f;
    }
    .new-segment-button-dark .new-segment-button-label-dark{
        background-color: #222428;
        width: 103%;
        border-radius: 40px;
        height: 28px;    
        color: white;
        border: 2px solid white;  
    }
    .league-cards {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        margin-bottom: 7px;
    }  

}
@media (prefers-color-scheme: dark) {
    @media screen and (max-width: 700px){
        .new-segment-button{
            --border-radius:40px;
            padding: 1px;
        }    
        .new-segment-button.segment-button-checked .new-segment-button-label{    
            background-color: #00365f;
            width: 115%;
            border-radius: 40px;
            height: 28px;
            color: #ffffff;
        }
        .new-segment-button .new-segment-button-label{
            background-color: transparent;
            width: 115%;
            border-radius: 40px;
            height: 28px;    
            color: #00365f;
        }
        .league-cards {
            margin: 7px 10px 7px 10px;
          }
    }
    @media screen and (min-width: 700px){
        .new-segment-button{
            --border-radius:40px;
            padding: 1px;
        }    
        .new-segment-button.segment-button-checked .new-segment-button-label{    
            background-color: #00365f;
            width: 106%;
            border-radius: 40px;
            height: 28px;
            color: white;
        }
        .new-segment-button .new-segment-button-label{
            background-color: transparent;
            width: 106%;
            border-radius: 40px;
            height: 28px;    
            color: #00365f;
        }
        .league-cards {
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
            margin-bottom: 7px;
        }
        
    }
    @media screen and (min-width: 900px){
        .new-segment-button{
            --border-radius:40px;
            padding: 1px;
        }
        .new-segment-button.segment-button-checked .new-segment-button-label{    
            background-color: white;
            width: 105%;
            border-radius: 40px;
            height: 28px;
            color: #00365f;
        }
        .new-segment-button .new-segment-button-label{
            background-color: transparent;
            width: 105%;
            border-radius: 40px;
            height: 28px;    
            color: #00365f;
        }
        .league-cards {
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }
    @media screen and (min-width: 1300px){
        .new-segment-button{
            --border-radius:40px;
            padding: 1px;
        }
        .new-segment-button.segment-button-checked .new-segment-button-label{    
            background-color: #00365f;
            width: 103%;
            border-radius: 40px;
            height: 28px;
            color: white;
        }
        .new-segment-button .new-segment-button-label{
            background-color: transparent;
            width: 103%;
            border-radius: 40px;
            height: 28px;    
            color: #00365f;
        }
        .league-cards {
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }
    
}

/* .new-segment-button-label .segment-button-checked {
    background-color: #00365f;
    width: 124%;
    border-radius: 40px;
    height: 27px;
    padding-top: 2px;
    color: white;
} */
/* .new-segment-button-label {
    background-color: white;
    width: 124%;
    border-radius: 40px;
    height: 27px;    
    color: #00365f;
    border: 2px solid #00365f;  
} */