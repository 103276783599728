/* .background-image {
  background-image: url('../img/ground_trees.png');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  height: 100%;
  overflow-y: auto;
} */
@media (max-width:2200px) and (min-width:800px){
  .background-image{
    width: 100%;
    height: 100%;
    background-image: url('../img/frames_1.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
@media only screen and (max-width:768px){
  .background-image{
    width: 100%;
    height: 100%;
    background-image: url('../img/frames_m1.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
@media only screen and (max-width: 467px) {
  .background-image{
    width: 100%;
    height: 100%;
    background-image: url('../img/frames_m1.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
.logo{
    height: 60px;
    margin: auto;
    width: 150px;
}
.h1-color{
    color: red;
}
.menu-pic {
  object-fit: cover;
  height: 27px;
  width: 27px;
  border-radius: 50px;
}
@media only screen and (max-height:600px) {
  .h-land{
    margin-top: 25vh;
  }
}

@media only screen and (min-height:600px) {
  .h-land{
    margin-top: 51vh;
  }
}
.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}
.mt-25{
    margin-top: 25px;
}
.mb-5{
    margin-bottom: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}
.full-width {
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
  }
  .family-done-button-1 {
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 40px;
    width: 80%;
    border-radius: 34.5px;
    max-width: 250px;
  }
  .family-next-2 {
    height: 30px;
    width: 234px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.26px;
    line-height: 30px;
    text-align: center;
  }
  .family-next-1 {
    height: 30px;
    width: 234px;
    color: #00365f;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.26px;
    line-height: 30px;
    text-align: center;
  }
  .family-send {
    background-color: #00365f;
  }
  .family-resend{
    background-color: #ffffff;
  }
  