@import url(https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700,800,900);
.background-white {
  background: #FFFFFF;
}
.transparent {
  --background: transparent !important;
}
.ripple-parent1 {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.signin-button {
  background: #00365f;
  width: 90%;
  border-radius: 50px;
}
.display-flex {
  display: flex;
}
.heading1 {
  padding-top: 20%;
  padding-left: 35%;
}

.user {
  padding-top: 15%;
  margin: auto;
  --background: white;
}
.mar{
  margin-left: -3%;
}
.pl-5{
  padding-left: 5px !important;
}
.pt-15{
  padding-top:15px;
}
.pass {
  padding-bottom: 0%;
  margin: auto;
  --background: white;
  
}
.checkbox{
  --background: transparent;
}
.inner-checkbox{
  --background: white;
  --border-color: black;
}
.wid{
  width: 95%;
  margin: auto;

}
@media (max-width:2200px) and (min-width:800px){
  .background-image1{
    width: 100%;
    height: 100%;
    background-image: url(/static/media/frames_1.18eaa7cb.svg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
  .pad-l {
    -webkit-padding-start: 0px !important;
            padding-inline-start: 0px !important;
  }
}
@media only screen and (max-width:768px){
  .background-image1{
    width: 100%;
    height: 100%;
    background-image: url(/static/media/frames_m1.4eecc73c.svg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
@media only screen and (max-width: 467px) {
  .background-image1{
    width: 100%;
    height: 100%;
    background-image: url(/static/media/frames_m1.4eecc73c.svg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
/* .background-image1 {
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  height: 46%;
  width: 100%;
  overflow-y: auto;
} */

@media only screen and (max-height:600px) {
  .h-login{
    margin-top: 25vh;
  }
}

@media only screen and (min-height:600px) {
  .h-login{
    margin-top: 50vh;
  }
}

.pad-0{
  padding: 0px;
}
.no-padding {
  width: 98%;
}
@media (prefers-color-scheme: dark) {
  .emailInput {
    border-radius: 50px!important;
    background-color: #EDF0F7!important;
    padding-left: 15px!important;
    padding-right: 15px !important;
    height: 40px !important;
    --placeholder-color: black !important;
    --color: black !important;
  }
  .select-box{
    color: gray !important
  }  
}
.emailInput {
  border-radius: 50px!important;
  background-color: #EDF0F7!important;
  padding-left: 15px!important;
  padding-right: 15px !important;
  height: 40px !important;
}

.alert-translucent.sc-ion-alert-ios-h .alert-wrapper.sc-ion-alert-ios{
  width: 100%;
  height: 82%;
}
.alert-translucent.sc-ion-alert-ios-h .alert-wrapper.sc-ion-alert-ios .alert-radio-group.sc-ion-alert-ios, .alert-checkbox-group.sc-ion-alert-ios{
  max-height: 90%;
}
.pl-20{
  padding-left:5% !important;
}
.range-slider{
  width: 90%;
  margin: auto;
  border-radius: 7px;
}
.grid-1-bg{
  background-color: rgb(252, 252, 252);
}
.item-bg{
  --background: rgb(252, 252, 252);
}
.popover-translucent.sc-ion-popover-ios-h .popover-content.sc-ion-popover-ios{
  height:30%;
}
.profile-avatar {
  height: 69px;
  width: 69px;
  border: 2px solid #313131;
  margin: auto;
}
.profile-button{
  height: 25px;
  width: 110px;
  border-radius: 20px;    
  font-size: 12px;
}
.pro-pic{
  object-fit: cover;
  height: 65px;
  width: 65px;
  border-radius: 50px;
}
.profile-modal .modal-wrapper{
  height: 18% !important;
  width: 95% !important;
  border-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
}
.profile-modal-password .modal-wrapper{
  height: 20% !important;
  width: 95% !important;
  border-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
}
.bg-lime{
  background-color: lime;
}
.select-modal{
  padding: 0px 20px;
}

.my-custom-class2 .modal-wrapper {
  height: 45%;
  margin-top: auto;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.edit-table-circle{
  border: 1px solid #00365f;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 6px 5px 5px 5px;
  margin: auto;
  color: #00365f;
}
.edit-putts-circle{
  border: 1px solid #00365f;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 5px 5px 5px 5px;
  margin: auto;
  color: #00365f;
  font-size: 12px;
}
.s-28{
  font-size: 35px;
}

/* //admin */
.s-35{
  font-size: 35px;
}
.admin-cards{
  height: 71px;
  width: 90px;
}
.profile-cards {
  margin: 0;
  box-shadow: none;
}
.box-shadow {
  box-shadow: 0px 4px 3px rgb(0 0 0 / 9%);
}
  .ripple-parent4 {
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-left: 35%;
    margin-top: 5%;
  }
  .heading4 {
    padding-top: 20%;
    padding-left: 35%;
  }  
  @media only screen and (max-height:600px) {
    .r-land{
      margin-top: 18vh;
    }
  }
  
  @media only screen and (min-height:600px) {
    .r-land{
      margin-top: 38vh;
    }
  }
  
  .float-right {
    float: right;
  }
  .float-left {
    float: left;
  }
  @media only screen and (min-width: 700px) {
    .login-fields {
      border: 1px solid gray;
      width: 60%;
      margin: auto;
      border-radius: 25px;
    }
    .register-row-fields {
      border: 1px solid gray;
      width: 60%;
      margin: auto;
      border-radius: 25px;
    }
    .register-fields {
      border: 1px solid gray;
      width: 60%;
      margin: 0px 2px;
      border-radius: 25px;
    }
    .signin-button {
      width: 30%;
    }
  }
  @media only screen and (max-width: 700px) {
    .login-fields {
      border: 1px solid gray;
      width: 90%;
      margin: auto;
      border-radius: 25px;
    }
    .register-row-fields {
      border: 1px solid gray;
      width: 98%;
      margin: auto;
      border-radius: 25px;
    }
    .register-fields {
      border: 1px solid gray;
      width: 97%;
      margin: 0px 2px;
      border-radius: 25px;
    }
  }
  .login-input input{
    padding-top: 3px !important;
  }
  .background-image2 {
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: cover;
    height: 31%;
    width:100%;
    overflow-y: auto;
  }
  .mar1{
    margin-left: -0%;
  }
  .list-avatar {
    width: 40px;
    height: 40px;
  }
  .admin-user-registrations-card {
    margin: 5px 3px 5px 0px;
    border-radius: 6px;
    width: 100%;
    height: 80px;
  }
  .registrations-avatar {
    height: 40px;
    width: 40px;
    margin-inline: 0px 13px;
    --border-radius: 0px;
  }
  .wid-100{
    width: 100%;
  }
  .ios .mtv-5{
    margin-top: 5%;
  }
  .md .mtv-5{
    margin-top: 4.5%;
  }
  .well-back-icon {
    height: 25px;
    width: 34px;
  }
.heading-terms{
  font-weight: 600;
  font-size: 16px;
  font-style: italic;
  text-decoration: underline;
  color: black;
}
.text-terms{
  font-size: 14.5px;
  color: black;
}
.br-10{
  border-radius: 10px;
}
.mrl-3{
  margin-left: -3%;
}
ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }

  ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }

  ion-menu.md ion-list {
    padding: 20px 0;
  }

  ion-menu.md ion-note {
    margin-bottom: 30px;
  }

  ion-menu.md ion-list-header, ion-menu.md ion-note {
    padding-left: 10px;
  }

  ion-menu.md ion-list#inbox-list {
    border-bottom: 1px solid #d7d8da;
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  }

  ion-menu.md ion-list#inbox-list ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 20px;
  }

  ion-menu.md ion-list#labels-list ion-list-header {
    font-size: 16px;
    margin-bottom: 18px;
    color: #757575;
    min-height: 26px;
  }

  ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
  }

  ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
  }

  ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }

  ion-menu.md ion-item ion-icon {
    color:var(--ion-color-primary);
  }

  ion-menu.md ion-item ion-label {
    font-weight: 500;
  }

  ion-menu.ios ion-content {
    --padding-bottom: 20px;
  }

  ion-menu.ios ion-list {
    padding: 20px 0 0 0;
  }

  ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
  }

  ion-menu.ios ion-item {
    --min-height: 50px;
  }

  ion-menu.ios ion-item ion-icon {
    font-size: 27px;
    color: var(--ion-color-primary);
  }

  ion-menu.ios ion-item ion-icon .selected{
    color: var(--ion-color-primary);
  }


  ion-menu.ios ion-list#labels-list ion-list-header {
    margin-bottom: 8px;
  }

  ion-menu.ios ion-list-header,
  ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
  }

  ion-menu.ios ion-note {
    margin-bottom: 8px;
  }

  ion-note {
    display: inline-block;
    font-size: 16px;
    color: var(--ion-color-medium-shade);
  }

  ion-item.selected {
    --color: var(--ion-color-primary);
  }
  .menu-logo{
    height: 60px;
    margin-top: 20px;
    width: 145px;
  }
  .menu_badge{
    position: absolute;
    right: 9px;
    top: 5px;
  }

/* .background-image {
  background-image: url('../img/ground_trees.png');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  height: 100%;
  overflow-y: auto;
} */
@media (max-width:2200px) and (min-width:800px){
  .background-image{
    width: 100%;
    height: 100%;
    background-image: url(/static/media/frames_1.18eaa7cb.svg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
@media only screen and (max-width:768px){
  .background-image{
    width: 100%;
    height: 100%;
    background-image: url(/static/media/frames_m1.4eecc73c.svg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
@media only screen and (max-width: 467px) {
  .background-image{
    width: 100%;
    height: 100%;
    background-image: url(/static/media/frames_m1.4eecc73c.svg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
.logo{
    height: 60px;
    margin: auto;
    width: 150px;
}
.h1-color{
    color: red;
}
.menu-pic {
  object-fit: cover;
  height: 27px;
  width: 27px;
  border-radius: 50px;
}
@media only screen and (max-height:600px) {
  .h-land{
    margin-top: 25vh;
  }
}

@media only screen and (min-height:600px) {
  .h-land{
    margin-top: 51vh;
  }
}
.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}
.mt-25{
    margin-top: 25px;
}
.mb-5{
    margin-bottom: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}
.full-width {
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
  }
  .family-done-button-1 {
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 40px;
    width: 80%;
    border-radius: 34.5px;
    max-width: 250px;
  }
  .family-next-2 {
    height: 30px;
    width: 234px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.26px;
    line-height: 30px;
    text-align: center;
  }
  .family-next-1 {
    height: 30px;
    width: 234px;
    color: #00365f;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.26px;
    line-height: 30px;
    text-align: center;
  }
  .family-send {
    background-color: #00365f;
  }
  .family-resend{
    background-color: #ffffff;
  }
  
ion-segment-button ion-label{
    font-weight: 550;
}
ion-modal ion-datetime {
    height: 382px;
}
.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    max-width: 300px;
}
.card-header{
    padding: 0px 0px 5px 0px;
}
.card-content{
    margin-top: 5px !important;
    padding: 0px 8px 0px 8px;
    font-size: 14px;
}
.user-details {
    margin-left: -10px;
}
.card-options {
    margin-left: 5px;
}
.card-options ion-row {
    float: right;
    font-size: 25px;
    padding-top: 5px;
}
.card-avatar{
    width: 40px;
    height: 40px;
}
.card-footer{
    padding: 10px 10px 10px 10px;
    border-top: 1px solid #d3d3d33b;
    justify-content: space-between;
}
.card-like-icon{
    width: 18px;
}
.card-delete-icon{
    width:20px;
}
.card-like-text{
    /* float: right;
    margin-top: 6%; */
    padding-left: 5px;
}
.card-comment-icon{
    width: 18px;
}
.card-comment{
    padding-left: 5px;
}
.edit-trash{
    color: rgb(185, 185, 185);
    margin-top: -7%;
}
.myclub-header{
    font-style: italic;
}

.main-fab {
    margin: 8px;
    height: 50px;
    width: 50px;
}

.add-tour-fab {
    margin: 8px -17px;
    height: 50px;
    width: 100px;
    --border-radius: 15px;
}

.compete-fab-list {
    flex-wrap: wrap-reverse;
    margin-left: -125px;
    align-items: flex-start;
}
.connect-fab-list {
    flex-wrap: wrap-reverse;
    margin-left: -50px;
    align-items: flex-start;
}
.create-post-fab{
    width: 80px;
    --border-radius: 14px !important;
    --background-activated: #cb3737;
    --background-focused: #cb3737;
    --background-hover: #cb3737;
}
.fab-button-in-list{
    --background-hover: #cb3737;
}
.about-fab{
    width: 80px;
    --border-radius: 14px !important;
}
.join-pack-fab{
    width: 110px;
    --border-radius: 14px !important;
}
.get-handicap-fab{
    width: 170px;
    --border-radius: 14px !important;
}
.share-tee-time{
    width: 100px;
    --border-radius: 14px !important;
}
.pack-post-fab{
    width: 120px;
    --border-radius: 14px !important;
}
.member-post-fab{
    width: 170px;
    --border-radius: 14px !important;
}
.community-post-fab{
    width: 160px;
    --border-radius: 14px !important;
}

.fs-29{
    font-size: 29px;
}
.fr{
    float: right;
}
.pt-9 {
    padding-top: 9px;
}
.pl-15 {
    padding-left: 15px;
}
.pd-5 {
    padding: 5px;
}
.packs-dropdown{
    padding: 5px 5px 0px 0px;
    color: black;
    margin-left: -5%;
    font-size: 16px;
}
.button-stay{
    height: 30px;
    width: 150px;
    border-radius: 9px;    
}
.button-delete{
    height: 30px;
    width: 150px;
    border-radius: 9px;    
}
.button-close{
    height: 30px;
    width: 100px;
    border-radius: 9px;    
}
.button-send{
    height: 40px;
    width: 150px;
    background-color: #18428e;
    border-radius: 5px;
}
.send-request{
    float: right;
    margin-right: 14px;
    margin-left: -10px;
}
.bg-orange{
    background-color: orange;
}
.tt-upper{
    text-transform: uppercase;
}
.wg-bold{
    font-weight: bold;
}
.select-pack-dropdown{
    border: 1px solid gray;
    width: 90%;
    margin: auto;
    border-radius: 7px;
}
.select-dropdown{
    border: 1px solid gray;
    width: 100%;
    margin: auto;
    border-radius: 7px;
}
.add-button{
    height: 40px;
    width: 67px;
    border-radius: 9px; 
}
.pad-t{
    padding: 15px 0px 0px 0px;
}
.bb-line{
    border-bottom: 1px solid lightgray;
}
.paypal-width{
    width: 70%;
    margin: auto;
}
.center {
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top:20vh;
}
.join-pack-modal .modal-wrapper {
    height: 70%;
    margin-top: auto;
    border-radius: 15px 15px 0px 0px;
}
.pending-scores-modal .modal-wrapper {
    height: 55%;
    margin-top: auto;
    border-radius: 15px 15px 0px 0px;
}
.quota-modal .modal-wrapper {
    height: 47%;
    margin-top: auto;
    border-radius: 15px 15px 0px 0px;
}
.close-bar{
    height: 18px;
}
@media (prefers-color-scheme: light) {
  .profile-text {
    color: #000000;
  }
}

.ripple-parent {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding-left: 30%;
  margin-top: 5%;
}
.text {
  padding-left: 30%;
}

ion-tab-bar {
  height: 55px;
  width: 100%;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
  border-top: none;
  justify-content: space-evenly;
  --background: #fff;
}

ion-tab-bar ion-label {
  font-size: 14px;
}

.wg-600 {
  font-weight: 600;
}
.wg-500 {
  font-weight: 500;
}
.s-8 {
  font-size: 8px;
}
.s-9 {
  font-size: 9px;
}
.s-10 {
  font-size: 10px;
}
.s-11 {
  font-size: 11px;
}
.s-12 {
  font-size: 12px;
}
.s-13 {
  font-size: 13px;
}
.s-14 {
  font-size: 14px;
}
.s-15 {
  font-size: 15px;
}
.s-16 {
  font-size: 16px;
}
.s-17 {
  font-size: 17px;
}
.s-18 {
  font-size: 18px;
}
.s-19 {
  font-size: 19px;
}
.s-20 {
  font-size: 20px;
}
.s-21{
  font-size: 21px;
}
.s-22 {
  font-size: 22px;
}
.s-23 {
  font-size: 23px;
}
.s-24 {
  font-size: 24px;
}
.s-25 {
  font-size: 25px;
}
.s-26 {
  font-size: 26px;
}
.s-27 {
  font-size: 27px;
}
.s-28 {
  font-size: 28px;
}
.s-29 {
  font-size: 29px;
}
.s-30 {
  font-size: 30px;
}
.red {
  color: #cb3737;
}
.pl-5 {
  padding-left: 5px;
}
.filter-badge {
  position: absolute;
  top: 6px;
  right: 8px;
  padding: 1px 4px;
  border-radius: 50%;
  background: #fff;
  color: #cb3737;
  font-size: 10px;
}
.bg {
  background-color: #00365f;
}
.color {
  color: #00365f;
}
.grey {
  color: rgb(138, 138, 138);
}
.underline {
  text-decoration: underline;
}
.card {
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 0px 5px 5px 5px;
}
.row-8 {
  height: 8vh;
}
.margin-auto {
  margin: auto;
}
.mt-5 {
  margin-top: 5px;
}
.white {
  color: white;
}
.inf-icon {
  padding-left: 3px;
  padding-top: 2px;
  position: absolute;
}
.mrl-10 {
  margin-left: -2vh;
}
.black {
  color: black;
}
.mrt-0-5 {
  margin-top: -0.5vh;
}
.tee-times-edit-text {
  margin-top: 3%;
  position: absolute;
}
.pad-0 {
  padding: 0px;
}
.tee-card {
  padding: 5px 5px 5px 5px;
  text-align: center;
  justify-content: center;
  margin-top: 0.5vh;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center:nth-child(1) {
  inset: auto 1px !important;
}
.apexcharts-canvas.apexchartsline.apexcharts-theme-light {
  margin: auto !important;
}
.stats-card {
  height: 1px;
    width: 90%;
    margin-left: 20px !important;
    box-shadow: none;
    margin-bottom: 0px;
}
.last-rounds {
  height: 25px;
  width: 100%;
  margin-left: 0px !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: none;
  margin-bottom: 0px;
}
.pt-3 {
  padding-top: 3px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.ta {
  text-align: center;
}
.grid-2 {
  padding: 0px 0px 0px 0px;
  margin-top: -2.5vh;
}

/* notifications css */
.not-button {
  height: 30px;
  border-radius: 21px;
  padding: 3px 20px 3px 20px;
  background: #45c7bb;
  color: white;
}
.search-card {
  margin: 3px 3px 5px 3px;
  border-radius: 6px;
  width: 97%;
  height: auto;
}
.admin-orders-card {
  margin: 3px 3px 5px 3px;
  border-radius: 6px;
  width: 97%;
  height: 120px;
}
.admin-user-logins-card {
  margin: 5px 3px 5px 0px;
  border-radius: 6px;
  width: 100%;
  height: 65px;
}
.matches-card {
  margin: 5px 3px 5px 0px;
  border-radius: 6px;
  width: 100%;
  height: auto;
}
.button-accept {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  margin-right: 5px;
}
.button-select-tees {
  height: 30px;
  width: 195px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #fff;
  color: #00365f;
  border: 1px solid #00365f;
}
.button-decline {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #fff;
  color: #cb3737;
  border: 1px solid #cb3737;
}
.h-100 {
  height: 100%;
  overflow-y: auto;
}
.checkin-cards {
  width: 97%;
  margin-left: 5px !important;
}
.points-table-row-blank ion-col{
  max-width: 100% !important;
  width: 100% !important;
  padding: 5px 0px 0px 0px !important;
}
.points-table-row-color {
  background: lightgrey;
}
.points-table-row-app-color {
  background: #00365f;
}
.points-table-row-blank, .points-table-row-app-color, .points-table-row-color  ion-col{
  padding: 5px 0px 5px 0px;
  text-align: center;
  font-size: 12px;
}
.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 10px;
  background: lightgray;
  border: 3px solid lightgray;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 19px;
}
.questions-tee-circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px 0px 0px 0px;
  background: lightgray;
  border: 3px solid lightgray;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  margin-left: 12px;
}
.profile-tee-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 1px 0px 0px 0px;
  background: lightgray;
  border: 3px solid lightgray;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  margin-left: 12px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.bg-green {
  background: #00365f;
  border: 3px solid #00365f;
  color: #fff;
}
.filters-modal .modal-wrapper {
  height: 65%;
  margin-top: auto;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.search-text-padding .native-input{
  padding: 0px 0px 10px;
}
/* .my-custom-class1 {
  height: 45%;
  top: 55%;
} */
.my-custom-class1 .modal-wrapper {
  height: 45%;
  top: 55%;
  margin-top: auto;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.sc-ion-modal-ios-h{
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.div {
  background-color: #00365f !important;
  color:white;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
/* .bg-gray{
    background-color: #fffbfb !important;
} */
.pad-ltb {
  padding: 10px 0px 10px 10px;
}
.fr {
  float: right;
}
.hole-button {
  box-shadow: 0px 1px 3px rgb(0 0 0 / 40%);
  border-radius: 20px;
}
.finish-button {
  height: 30px;
  width: 120px;
  background: white;
}
.gray {
  color: gray !important;
}
.notification-item {
  --padding-start: 11px;
}
.score-summary-card {
  box-shadow: rgb(0 0 0 / 45%) 0px 0px 2px;
  background: rgb(226 226 226 / 12%);
}
.max-wid-toolbar{
  max-width: 100%;  
}
.users-card{
  width: 99%;
  margin: 1px 1px 1px 1px;
  border-radius: 6px;  
}
.pending-height{
  max-height: 75%;
  min-height: 74%;
}
.notification-dot{
  position: absolute;
  top: 6px;
  right: 5px;
  padding: 4px 4px;
  border-radius: 50%;
  background: #cb3737;
}
.notification{
  position: absolute;
  top: 6px;
  right: 5px;
  padding: 4px 4px;
  border-radius: 50%;
 
}
.new-notification{
  position: relative;
  top: -1px;
  right: -2px;
  padding: 1px 5px 2px 4px;
  border-radius: 20px;
  background: #cb3737;
  color: white;
  font-size: 10px;
}
.main_logo{
    width: 130px;
    padding: 7px 0px 0px 0px;
}
.pro_pic{
    object-fit: cover;
    height: 33px;
    width: 33px;
    border-radius: 50px;
}
.bg-light-gray {
    background: #e9e9e9;
}
ion-fab-button{
    --background: #cb3737;
}
.tt-card {
    padding-left: 8px;
    padding-right: 8px;
    background-color: #00365f;
    color: #fff;
}
.attest-button {
    border: 1px solid #00365f;
    border-radius: 10px;
    padding: 1px 5px 2px 5px;
    color: #cb3737;
    margin-left: 5px;
}
.nsp-card {
    padding-left: 8px;
    padding-right: 8px;
    background-color: #00365fc7;
    color: #fff;
}
.social-options {
    position: absolute;
    right: 0;
    font-size: 20px;
} 
.social-name {
    margin-left: 10px;
}
.text-align-right {
    text-align: right;
}
.text-align-left {
    text-align: left;
}
.s-13{
    font-size: 13px;
}
.s-15{
    font-size: 15px;
}
.black{
    color: black;
}
.pb-10 {
    padding-bottom: 10px;
}
.pad-10{
    padding: 10px 10px 10px 10px;
}
.pad-5{
    padding: 5px 5px 5px 5px;
}
.pro-credits{
    margin-top: 12%;
}
.pro-button{
    height: 30px;
    width: 100%;
}
.pro-shop-icon{
    width: 20px;
}
.range-left {
    margin-right: -1px;
    padding: 0px 0px 3px 0px;
}
.range-right {
    margin-left: -1px;
    padding: 0px 0px 3px 0px;
}
.pro-shop-cards {
    margin: 3px;
    /*border: 1px solid #00365f;*/
    padding: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 15px;
}
.noticebaord-cards {
    margin: 5px 10px;
    /*border: 1px solid #00365f;*/
    padding: 10px;
}
.tour-cards {
    margin: 5px;
    /* border: 1px solid #00365f; */
    padding: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 15px;
    height: 125px;
}
.pro-shop-pic {
    object-fit: contain;
    height: 100px;
    width: 100px;
    border-radius: 0px;
}
.tours-pic {
    object-fit: contain;
    height: 80px;
    width: 80px;
    border-radius: 0px;
}
.justify-content-space-between {
    justify-content: space-between;
}
.justify-content-space-evenly {
    justify-content: space-evenly;
}
.new-segment{
    background: rgb(221 221 221 / 54%);
    border-radius: 20px;
}    
@media screen and (max-width: 700px){
    .new-segment-button{
        --border-radius:40px;
        padding: 1px;
    }    
    .new-segment-button.segment-button-checked .new-segment-button-label{    
        background-color: #00365f;
        width: 115%;
        border-radius: 40px;
        height: 28px;
        color: white;
    }
    .new-segment-button .new-segment-button-label{
        background-color: transparent;
        width: 115%;
        border-radius: 40px;
        height: 28px;    
        color: #00365f;
        border: 2px solid transparent;  
    }
    .new-segment-button-dark{
        --border-radius:40px;
    }    
    .new-segment-button-dark.segment-button-checked .new-segment-button-label-dark{    
        background-color: white;
        width: 115%;
        border-radius: 40px;
        height: 28px;
        color: #00365f;
    }
    .new-segment-button-dark .new-segment-button-label-dark{
        background-color: #222428;
        width: 115%;
        border-radius: 40px;
        height: 28px;    
        color: white;
        border: 2px solid white;  
    }
    .league-cards {
        margin: 7px 10px 7px 10px;
      }
}
@media screen and (min-width: 700px){
    .new-segment-button{
        --border-radius:40px;
        padding: 1px;
    }    
    .new-segment-button.segment-button-checked .new-segment-button-label{    
        background-color: #00365f;
        width: 106%;
        border-radius: 40px;
        height: 28px;
        color: white;
    }
    .new-segment-button .new-segment-button-label{
        background-color: transparent;
        width: 106%;
        border-radius: 40px;
        height: 28px;    
        color: #00365f;
        border: 2px solid transparent;  
    }
    .new-segment-button-dark{
        --border-radius:40px;
    }    
    .new-segment-button-dark.segment-button-checked .new-segment-button-label-dark{    
        background-color: white;
        width: 106%;
        border-radius: 40px;
        height: 28px;
        color: #00365f;
    }
    .new-segment-button-dark .new-segment-button-label-dark{
        background-color: #222428;
        width: 106%;
        border-radius: 40px;
        height: 28px;    
        color: white;
        border: 2px solid white;  
    }  
    .league-cards {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        margin-bottom: 7px;
    }  
}
@media screen and (min-width: 900px){
    .new-segment-button{
        --border-radius:40px;
        padding: 1px;
    }
    .new-segment-button.segment-button-checked .new-segment-button-label{    
        background-color: #00365f;
        width: 105%;
        border-radius: 40px;
        height: 28px;
        color: white;
    }
    .new-segment-button .new-segment-button-label{
        background-color: transparent;
        width: 105%;
        border-radius: 40px;
        height: 28px;    
        color: #00365f;
        border: 2px solid transparent;  
    }
    .new-segment-button-dark{
        --border-radius:40px;
    }
    .new-segment-button-dark.segment-button-checked .new-segment-button-label-dark{    
        background-color: white;
        width: 105%;
        border-radius: 40px;
        height: 28px;
        color: #00365f;
    }
    .new-segment-button-dark .new-segment-button-label-dark{
        background-color: #222428;
        width: 105%;
        border-radius: 40px;
        height: 28px;    
        color: white;
        border: 2px solid white;  
    }
    .league-cards {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        margin-bottom: 7px;
    }  

}
@media screen and (min-width: 1300px){
    .new-segment-button{
        --border-radius:40px;
        padding: 1px;
    }
    .new-segment-button.segment-button-checked .new-segment-button-label{    
        background-color: #00365f;
        width: 103%;
        border-radius: 40px;
        height: 28px;
        color: white;
    }
    .new-segment-button .new-segment-button-label{
        background-color: transparent;
        width: 103%;
        border-radius: 40px;
        height: 28px;    
        color: #00365f;
        border: 2px solid transparent;  
    }
    .new-segment-button-dark{
        --border-radius:40px;
    }
    .new-segment-button-dark.segment-button-checked .new-segment-button-label-dark{    
        background-color: white;
        width: 103%;
        border-radius: 40px;
        height: 28px;
        color: #00365f;
    }
    .new-segment-button-dark .new-segment-button-label-dark{
        background-color: #222428;
        width: 103%;
        border-radius: 40px;
        height: 28px;    
        color: white;
        border: 2px solid white;  
    }
    .league-cards {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        margin-bottom: 7px;
    }  

}
@media (prefers-color-scheme: dark) {
    @media screen and (max-width: 700px){
        .new-segment-button{
            --border-radius:40px;
            padding: 1px;
        }    
        .new-segment-button.segment-button-checked .new-segment-button-label{    
            background-color: #00365f;
            width: 115%;
            border-radius: 40px;
            height: 28px;
            color: #ffffff;
        }
        .new-segment-button .new-segment-button-label{
            background-color: transparent;
            width: 115%;
            border-radius: 40px;
            height: 28px;    
            color: #00365f;
        }
        .league-cards {
            margin: 7px 10px 7px 10px;
          }
    }
    @media screen and (min-width: 700px){
        .new-segment-button{
            --border-radius:40px;
            padding: 1px;
        }    
        .new-segment-button.segment-button-checked .new-segment-button-label{    
            background-color: #00365f;
            width: 106%;
            border-radius: 40px;
            height: 28px;
            color: white;
        }
        .new-segment-button .new-segment-button-label{
            background-color: transparent;
            width: 106%;
            border-radius: 40px;
            height: 28px;    
            color: #00365f;
        }
        .league-cards {
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
            margin-bottom: 7px;
        }
        
    }
    @media screen and (min-width: 900px){
        .new-segment-button{
            --border-radius:40px;
            padding: 1px;
        }
        .new-segment-button.segment-button-checked .new-segment-button-label{    
            background-color: white;
            width: 105%;
            border-radius: 40px;
            height: 28px;
            color: #00365f;
        }
        .new-segment-button .new-segment-button-label{
            background-color: transparent;
            width: 105%;
            border-radius: 40px;
            height: 28px;    
            color: #00365f;
        }
        .league-cards {
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }
    @media screen and (min-width: 1300px){
        .new-segment-button{
            --border-radius:40px;
            padding: 1px;
        }
        .new-segment-button.segment-button-checked .new-segment-button-label{    
            background-color: #00365f;
            width: 103%;
            border-radius: 40px;
            height: 28px;
            color: white;
        }
        .new-segment-button .new-segment-button-label{
            background-color: transparent;
            width: 103%;
            border-radius: 40px;
            height: 28px;    
            color: #00365f;
        }
        .league-cards {
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }
    
}

/* .new-segment-button-label .segment-button-checked {
    background-color: #00365f;
    width: 124%;
    border-radius: 40px;
    height: 27px;
    padding-top: 2px;
    color: white;
} */
/* .new-segment-button-label {
    background-color: white;
    width: 124%;
    border-radius: 40px;
    height: 27px;    
    color: #00365f;
    border: 2px solid #00365f;  
} */
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

*:not(ion-icon) {
  font-family: 'Lato' !important;
}

.vh-8 {
  margin-top: 8vh;
}

.vh-10 {
  margin-top: 10vh;
}

.vh-12 {
  margin-top: 12vh;
}

.vh-15 {
  margin-top: 15vh;
}
.mt-0 {
  margin-top: -8px !important;
}
.mt-30 {
  margin-top: 30px;
}
.pointer {
  text-decoration: none;
  cursor: pointer;
}
.create-button-outline {
  border: 1px solid #00365f;
    border-radius: 5px;
    padding: 0px 3px 3px 3px;
}
.create-button-solid-yellow {
    border: 1px solid white;
    border-radius: 5px;
    padding: 0px 3px 3px 3px;
    color: #00365f;
    background-color:#ffd700;

}
.create-button-solid-white {
  border: 1px solid white;
  border-radius: 5px;
  padding: 0px 5px 5px 5px;
  color: #00365f;
  background-color:white;
}

.pro-shop-button {
  background: #fff;
  color: #00365f;
  border: 1px solid #00365f;
  font-weight: 600;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  width: 100%;
  margin: 5px;
}
.gold-background {
  background-color: #ffd700;
  background-image: linear-gradient(315deg, #b57c04 0%, #f6c254 74%);
  border-radius: 50%;
  padding: 2px 6px 2px 5px;
  color: #fff;
  font-size: 12px;
}
.silver-background {
  background-color: #c9c9c9;
  background-image: linear-gradient(315deg, #515151 0%, #dfdfdf 74%);
  border-radius: 50%;
  padding: 2px 6px 2px 5px;
  color: #fff;
  font-size: 12px;
}
.bronze-background {
  background-color: #a44200;
  background-image: linear-gradient(315deg, #7b3202 20%, #cc7704 74%);
  border-radius: 50%;
  padding: 2px 6px 2px 5px;
  color:#fff;
  font-size: 12px;
}
.vh-20 {
  margin-top: 20vh;
}

.vh-25 {
  margin-top: 25vh;
}

.vh-30 {
  margin-top: 30vh;
}

.vh-35 {
  margin-top: 35vh;
}

.vh-40 {
  margin-top: 40vh;
}

.vh-45 {
  margin-top: 45vh;
}

.vh-50 {
  margin-top: 50vh;
}
.w-100 {
  font-weight: 100;
}
.w-200 {
  font-weight: 200;
}
.w-300 {
  font-weight: 300;
}
.w-400 {
  font-weight: 400;
}
.w-500 {
  font-weight: 500;
}
.w-600 {
  font-weight: 600;
}
.w-700 {
  font-weight: 700;
}
.w-800 {
  font-weight: 800;
}
.pd-r-5 {
  padding-right: 5px;
}
.pd-l-5 {
  padding-left: 5px;
}
.pd-r-10 {
  padding-right: 10px;
}
.pd-l-10 {
  padding-left: 10px;
}
.slot-start-padding {
  padding: 5px 0px 0px 6px;
}
.slot-end-padding {
  padding: 2px 6px 0px 0px;
}
.color-blue {
  color: #00365f !important;
}

.color-red {
  color: #cb3737 !important;
}

.color-black {
  color: #000000 !important;
}

.color-gray {
  color: #bfbfbf !important;
}
.color-green {
  color: #00ac00;
}
.color-dark-gray {
  color: #626262 !important;
}

.bg-blue {
  background-color: #00365f !important;
  color: #FFF;
}

.bg-red {
  background-color: #cb3737 !important;
  color: #FFF;
}

.bg-black {
  background-color: #000000 !important;
  color: #000000;
}

.bg-gray {
  background-color: #e0e0e0 !important;
}
.blue-button {
  background: #00365f;
  padding: 10px 20px 10px 20px;
  color: #fff;
  border-radius: 25px;
}
.blue-anchor-button {
  text-decoration: none;
    cursor: pointer;
    background: #00365f;
    color: #fff;
    padding: 10px 15px 10px 15px;
    border-radius: 25px;
}
.popover-content.sc-ion-popover-ios {
  width: 97%;
  left: 10px;
}
ion-list-header {
  font-size: 16px;
}
.select-interface-option ion-label{
  font-size: 14px !important;
}
.events-card-options ion-row{
  float: right;
  font-size: 25px;
  padding-top: 5px;
}
.social-card {
  margin: 3px 3px 5px 3px;
}
.segment-toolbar {
  height: 45px;
    /* box-shadow: 0px 0px 6px rgb(0 0 0 / 19%); */
    --background: #fff;
}
.profile-toolbar {
  box-shadow: 0px 0px 6px rgb(0 0 0 / 19%);
  padding: 5px 5px 10px 5px;
  border: 1px solid #00365f;
  border-radius: 10px 10px 0px 0px;
}
.toolbar-top {
  height: 45px;
}
.button-border-20 {
  border-radius: 20px;
}
.ios-toolbar-top {
  padding-top: 45px;
  height: 90px;
  --background: #fff;
  /*box-shadow: 0px 4px 3px rgb(0 0 0 / 9%);*/
}
.dynamic-content-div > p {
  margin-top: 0;
}
.custom-input > input{
  padding-top: 8px !important;
}
.profile-link {
  cursor: pointer;
  color: #00365f;
}
.disabled-button {
  opacity: 0.5;
}
.bottom-menu-fab {
  margin-bottom: -60px;
}
.bottom-fab-list {
  display: flex;
  flex-direction: row
}
.fab-button-padding {
  margin: 0px 9px;
}
.back-fab-button {
  margin-bottom: 35px;
}
.input-margin {
  margin: 0px 17px;
  border: none;
}
.fab-icon {
  font-size: 25px;
  color: white;
}
.vh-5 {
  margin-top: 5vh;
}
.single-segment-toolbar {
  height: 65px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 19%);
}
.custom-profile-ion-range-slider{
  --bar-background: #cb3737;
    --bar-border-radius: 50px;
    --bar-height: 2px;
    --height: 15px;
    --knob-background: #00365f;
    --knob-border-radius: 50px;
    --knob-size: 15px;
}
.custom-ion-range-slider{
  --bar-background: #cb3737;
    --bar-border-radius: 50px;
    --bar-height: 2px;
    --height: 44px;
    --knob-background: #00365f;
    --knob-border-radius: 50px;
    --knob-size: 15px;
}
.range-disabled {
  opacity: 1;
}
::before {
  opacity: 0;
}
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #00365f;
  --ion-color-primary-rgb: 0, 54, 95;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003054;
  --ion-color-primary-tint: #1a4a6f;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --indicator-color: transparent;
  --indicator-height: 0;
  --ion-toolbar-segment-indicator-color: transparent;

}

@media (prefers-color-scheme: dark) {
  /*
   * Light Colors
   * -------------------------------------------
   */

  body {

  }

  /*
   * iOS Light Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Light Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
  }

  ion-segment{
    background: transparent;
    width: 99%;
    margin-top: 2px;
    margin-bottom: 2px;
    min-height:32px !important;
  }
  ion-segment-button {
    --color-checked: #cb3737;
    --color: #ffffff;
    font-weight: 550;
  }
  ion-tab-button {
    color: #00365f !important;
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

@media (prefers-color-scheme: light) {
  /*
   * Light Colors
   * -------------------------------------------
   */

  body {

  }

  /*
   * iOS Light Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Light Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
  }

  ion-segment{
    background: #00365f;
    width: 99%;
    margin-top: 2px;
    margin-bottom: 2px;
    min-height:32px !important;
  }
  ion-segment-button {
    --color-checked: #cb3737;
    text-transform: capitalize;
    --color: #ffffff;
    font-weight: 550;
  }
  ion-tab-button {
    color: #00365f !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.tours-content{
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0px;
}
.content-height{
  max-height:50%
 }

@media only screen and (max-width:700px) {
  .content-height{
    max-height:40%
   }
}
/* Ipad Mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .content-height{
    max-height:70%
   }
}
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .content-height{
    max-height:70%
   }
}

/* Ipad Air */
@media only screen and (min-device-width: 820px) and (min-height: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
  .content-height{
    max-height:80% !important
   }
}

.score-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
}
.user-quota-align{
  width: 40%;
  margin :0px 10px
}
.user-driving-align{
  width: 60%;
}
.quota-text-align{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}
.userName-align{
  width: 100%;
  display: flex;
  justify-content: center;
}
.driving-distance-align{
  display: flex;
  justify-content: flex-end;
  margin:0px 15px;
}
.nsc-scores-list{
  display: contents !important;
  max-width: 100% !important;
}
.nsc-result{
  position: absolute !important;
  right:0 !important;
}
.won-tied-box{
  margin: 10px 5px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
.won-tied-header{
  background-color: rgb(239, 239, 239);
  width: 100%;
  height:37px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.won-tied-content{
  background-color: #fff;
  width: 100%;
  height:40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.won-tied-headings{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading{
  font-size: 12px;
  font-weight: 900;
}
.game-count{
  font-size: 14px;
}
.game-points{
  font-size: 14px;
  color:limegreen;
}
.scoring-record-box{
  margin: 10px 5px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  height: 300px;
  overflow-y: scroll;
}
.scoring-record-header{
  background-color: rgb(239, 239, 239);
  width: 100%;
  height:37px;
  display: flex;
  justify-content:center;
  align-items: center;
}
.week-header{
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 border-bottom:1px solid rgb(231, 230, 232) ;
}
.score-description{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  width: 56%;
}
.score-week-align{
  width: 44%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px;
}

.score-heading-color{
  color: #FF0000;
  font-size: 12px;
}
.week-heading-color{
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.playing-count{
  color:#000000;
  font-weight: 900;
  font-size: 14px;
}
.score-card-content-align{
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}
.match-heading{
  color: #FF0000;
}
.score-footer{
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin: 0px;
  padding: 3px 0px;
}
.my-custom-class {
    --height: 600px !important;
}
@media only screen and (max-width: 600px) {
  .my-custom-class {
    --height: 100% !important;
  }
  .scoring-record-box{
    height: 500px !important;
    overflow-y: scroll;
  }
}

.no-space{
  white-space: nowrap;
}

