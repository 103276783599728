@media (prefers-color-scheme: light) {
  .profile-text {
    color: #000000;
  }
}

.ripple-parent {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding-left: 30%;
  margin-top: 5%;
}
.text {
  padding-left: 30%;
}

ion-tab-bar {
  height: 55px;
  width: 100%;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
  border-top: none;
  justify-content: space-evenly;
  --background: #fff;
}

ion-tab-bar ion-label {
  font-size: 14px;
}

.wg-600 {
  font-weight: 600;
}
.wg-500 {
  font-weight: 500;
}
.s-8 {
  font-size: 8px;
}
.s-9 {
  font-size: 9px;
}
.s-10 {
  font-size: 10px;
}
.s-11 {
  font-size: 11px;
}
.s-12 {
  font-size: 12px;
}
.s-13 {
  font-size: 13px;
}
.s-14 {
  font-size: 14px;
}
.s-15 {
  font-size: 15px;
}
.s-16 {
  font-size: 16px;
}
.s-17 {
  font-size: 17px;
}
.s-18 {
  font-size: 18px;
}
.s-19 {
  font-size: 19px;
}
.s-20 {
  font-size: 20px;
}
.s-21{
  font-size: 21px;
}
.s-22 {
  font-size: 22px;
}
.s-23 {
  font-size: 23px;
}
.s-24 {
  font-size: 24px;
}
.s-25 {
  font-size: 25px;
}
.s-26 {
  font-size: 26px;
}
.s-27 {
  font-size: 27px;
}
.s-28 {
  font-size: 28px;
}
.s-29 {
  font-size: 29px;
}
.s-30 {
  font-size: 30px;
}
.red {
  color: #cb3737;
}
.pl-5 {
  padding-left: 5px;
}
.filter-badge {
  position: absolute;
  top: 6px;
  right: 8px;
  padding: 1px 4px;
  border-radius: 50%;
  background: #fff;
  color: #cb3737;
  font-size: 10px;
}
.bg {
  background-color: #00365f;
}
.color {
  color: #00365f;
}
.grey {
  color: rgb(138, 138, 138);
}
.underline {
  text-decoration: underline;
}
.card {
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 0px 5px 5px 5px;
}
.row-8 {
  height: 8vh;
}
.margin-auto {
  margin: auto;
}
.mt-5 {
  margin-top: 5px;
}
.white {
  color: white;
}
.inf-icon {
  padding-left: 3px;
  padding-top: 2px;
  position: absolute;
}
.mrl-10 {
  margin-left: -2vh;
}
.black {
  color: black;
}
.mrt-0-5 {
  margin-top: -0.5vh;
}
.tee-times-edit-text {
  margin-top: 3%;
  position: absolute;
}
.pad-0 {
  padding: 0px;
}
.tee-card {
  padding: 5px 5px 5px 5px;
  text-align: center;
  justify-content: center;
  margin-top: 0.5vh;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center:nth-child(1) {
  inset: auto 1px !important;
}
.apexcharts-canvas.apexchartsline.apexcharts-theme-light {
  margin: auto !important;
}
.stats-card {
  height: 1px;
    width: 90%;
    margin-left: 20px !important;
    box-shadow: none;
    margin-bottom: 0px;
}
.last-rounds {
  height: 25px;
  width: 100%;
  margin-left: 0px !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: none;
  margin-bottom: 0px;
}
.pt-3 {
  padding-top: 3px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.ta {
  text-align: center;
}
.grid-2 {
  padding: 0px 0px 0px 0px;
  margin-top: -2.5vh;
}

/* notifications css */
.not-button {
  height: 30px;
  border-radius: 21px;
  padding: 3px 20px 3px 20px;
  background: #45c7bb;
  color: white;
}
.search-card {
  margin: 3px 3px 5px 3px;
  border-radius: 6px;
  width: 97%;
  height: auto;
}
.admin-orders-card {
  margin: 3px 3px 5px 3px;
  border-radius: 6px;
  width: 97%;
  height: 120px;
}
.admin-user-logins-card {
  margin: 5px 3px 5px 0px;
  border-radius: 6px;
  width: 100%;
  height: 65px;
}
.matches-card {
  margin: 5px 3px 5px 0px;
  border-radius: 6px;
  width: 100%;
  height: auto;
}
.button-accept {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  margin-right: 5px;
}
.button-select-tees {
  height: 30px;
  width: 195px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #fff;
  color: #00365f;
  border: 1px solid #00365f;
}
.button-decline {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #fff;
  color: #cb3737;
  border: 1px solid #cb3737;
}
.h-100 {
  height: 100%;
  overflow-y: auto;
}
.checkin-cards {
  width: 97%;
  margin-left: 5px !important;
}
.points-table-row-blank ion-col{
  max-width: 100% !important;
  width: 100% !important;
  padding: 5px 0px 0px 0px !important;
}
.points-table-row-color {
  background: lightgrey;
}
.points-table-row-app-color {
  background: #00365f;
}
.points-table-row-blank, .points-table-row-app-color, .points-table-row-color  ion-col{
  padding: 5px 0px 5px 0px;
  text-align: center;
  font-size: 12px;
}
.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 10px;
  background: lightgray;
  border: 3px solid lightgray;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 19px;
}
.questions-tee-circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px 0px 0px 0px;
  background: lightgray;
  border: 3px solid lightgray;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  margin-left: 12px;
}
.profile-tee-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 1px 0px 0px 0px;
  background: lightgray;
  border: 3px solid lightgray;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  margin-left: 12px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.bg-green {
  background: #00365f;
  border: 3px solid #00365f;
  color: #fff;
}
.filters-modal .modal-wrapper {
  height: 65%;
  margin-top: auto;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.search-text-padding .native-input{
  padding: 0px 0px 10px;
}
/* .my-custom-class1 {
  height: 45%;
  top: 55%;
} */
.my-custom-class1 .modal-wrapper {
  height: 45%;
  top: 55%;
  margin-top: auto;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.sc-ion-modal-ios-h{
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.div {
  background-color: #00365f !important;
  color:white;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
/* .bg-gray{
    background-color: #fffbfb !important;
} */
.pad-ltb {
  padding: 10px 0px 10px 10px;
}
.fr {
  float: right;
}
.hole-button {
  box-shadow: 0px 1px 3px rgb(0 0 0 / 40%);
  border-radius: 20px;
}
.finish-button {
  height: 30px;
  width: 120px;
  background: white;
}
.gray {
  color: gray !important;
}
.notification-item {
  --padding-start: 11px;
}
.score-summary-card {
  box-shadow: rgb(0 0 0 / 45%) 0px 0px 2px;
  background: rgb(226 226 226 / 12%);
}
.max-wid-toolbar{
  max-width: 100%;  
}
.users-card{
  width: 99%;
  margin: 1px 1px 1px 1px;
  border-radius: 6px;  
}
.pending-height{
  max-height: 75%;
  min-height: 74%;
}
.notification-dot{
  position: absolute;
  top: 6px;
  right: 5px;
  padding: 4px 4px;
  border-radius: 50%;
  background: #cb3737;
}
.notification{
  position: absolute;
  top: 6px;
  right: 5px;
  padding: 4px 4px;
  border-radius: 50%;
 
}
.new-notification{
  position: relative;
  top: -1px;
  right: -2px;
  padding: 1px 5px 2px 4px;
  border-radius: 20px;
  background: #cb3737;
  color: white;
  font-size: 10px;
}