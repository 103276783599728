  .ripple-parent4 {
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-left: 35%;
    margin-top: 5%;
  }
  .heading4 {
    padding-top: 20%;
    padding-left: 35%;
  }  
  @media only screen and (max-height:600px) {
    .r-land{
      margin-top: 18vh;
    }
  }
  
  @media only screen and (min-height:600px) {
    .r-land{
      margin-top: 38vh;
    }
  }
  
  .float-right {
    float: right;
  }
  .float-left {
    float: left;
  }
  @media only screen and (min-width: 700px) {
    .login-fields {
      border: 1px solid gray;
      width: 60%;
      margin: auto;
      border-radius: 25px;
    }
    .register-row-fields {
      border: 1px solid gray;
      width: 60%;
      margin: auto;
      border-radius: 25px;
    }
    .register-fields {
      border: 1px solid gray;
      width: 60%;
      margin: 0px 2px;
      border-radius: 25px;
    }
    .signin-button {
      width: 30%;
    }
  }
  @media only screen and (max-width: 700px) {
    .login-fields {
      border: 1px solid gray;
      width: 90%;
      margin: auto;
      border-radius: 25px;
    }
    .register-row-fields {
      border: 1px solid gray;
      width: 98%;
      margin: auto;
      border-radius: 25px;
    }
    .register-fields {
      border: 1px solid gray;
      width: 97%;
      margin: 0px 2px;
      border-radius: 25px;
    }
  }
  .login-input input{
    padding-top: 3px !important;
  }
  .background-image2 {
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: cover;
    height: 31%;
    width:100%;
    overflow-y: auto;
  }
  .mar1{
    margin-left: -0%;
  }
  .list-avatar {
    width: 40px;
    height: 40px;
  }
  .admin-user-registrations-card {
    margin: 5px 3px 5px 0px;
    border-radius: 6px;
    width: 100%;
    height: 80px;
  }
  .registrations-avatar {
    height: 40px;
    width: 40px;
    margin-inline: 0px 13px;
    --border-radius: 0px;
  }
  .wid-100{
    width: 100%;
  }
  .ios .mtv-5{
    margin-top: 5%;
  }
  .md .mtv-5{
    margin-top: 4.5%;
  }
  .well-back-icon {
    height: 25px;
    width: 34px;
  }
.heading-terms{
  font-weight: 600;
  font-size: 16px;
  font-style: italic;
  text-decoration: underline;
  color: black;
}
.text-terms{
  font-size: 14.5px;
  color: black;
}
.br-10{
  border-radius: 10px;
}
.mrl-3{
  margin-left: -3%;
}