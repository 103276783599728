.background-white {
  background: #FFFFFF;
}
.transparent {
  --background: transparent !important;
}
.ripple-parent1 {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.signin-button {
  background: #00365f;
  width: 90%;
  border-radius: 50px;
}
.display-flex {
  display: flex;
}
.heading1 {
  padding-top: 20%;
  padding-left: 35%;
}

.user {
  padding-top: 15%;
  margin: auto;
  --background: white;
}
.mar{
  margin-left: -3%;
}
.pl-5{
  padding-left: 5px !important;
}
.pt-15{
  padding-top:15px;
}
.pass {
  padding-bottom: 0%;
  margin: auto;
  --background: white;
  
}
.checkbox{
  --background: transparent;
}
.inner-checkbox{
  --background: white;
  --border-color: black;
}
.wid{
  width: 95%;
  margin: auto;

}
@media (max-width:2200px) and (min-width:800px){
  .background-image1{
    width: 100%;
    height: 100%;
    background-image: url('../img/frames_1.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
  .pad-l {
    padding-inline-start: 0px !important;
  }
}
@media only screen and (max-width:768px){
  .background-image1{
    width: 100%;
    height: 100%;
    background-image: url('../img/frames_m1.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
@media only screen and (max-width: 467px) {
  .background-image1{
    width: 100%;
    height: 100%;
    background-image: url('../img/frames_m1.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
/* .background-image1 {
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  height: 46%;
  width: 100%;
  overflow-y: auto;
} */

@media only screen and (max-height:600px) {
  .h-login{
    margin-top: 25vh;
  }
}

@media only screen and (min-height:600px) {
  .h-login{
    margin-top: 50vh;
  }
}

.pad-0{
  padding: 0px;
}
.no-padding {
  width: 98%;
}
@media (prefers-color-scheme: dark) {
  .emailInput {
    border-radius: 50px!important;
    background-color: #EDF0F7!important;
    padding-left: 15px!important;
    padding-right: 15px !important;
    height: 40px !important;
    --placeholder-color: black !important;
    --color: black !important;
  }
  .select-box{
    color: gray !important
  }  
}
.emailInput {
  border-radius: 50px!important;
  background-color: #EDF0F7!important;
  padding-left: 15px!important;
  padding-right: 15px !important;
  height: 40px !important;
}

.alert-translucent.sc-ion-alert-ios-h .alert-wrapper.sc-ion-alert-ios{
  width: 100%;
  height: 82%;
}
.alert-translucent.sc-ion-alert-ios-h .alert-wrapper.sc-ion-alert-ios .alert-radio-group.sc-ion-alert-ios, .alert-checkbox-group.sc-ion-alert-ios{
  max-height: 90%;
}
.pl-20{
  padding-left:5% !important;
}
.range-slider{
  width: 90%;
  margin: auto;
  border-radius: 7px;
}
.grid-1-bg{
  background-color: rgb(252, 252, 252);
}
.item-bg{
  --background: rgb(252, 252, 252);
}
.popover-translucent.sc-ion-popover-ios-h .popover-content.sc-ion-popover-ios{
  height:30%;
}
.profile-avatar {
  height: 69px;
  width: 69px;
  border: 2px solid #313131;
  margin: auto;
}
.profile-button{
  height: 25px;
  width: 110px;
  border-radius: 20px;    
  font-size: 12px;
}
.pro-pic{
  object-fit: cover;
  height: 65px;
  width: 65px;
  border-radius: 50px;
}
.profile-modal .modal-wrapper{
  height: 18% !important;
  width: 95% !important;
  border-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
}
.profile-modal-password .modal-wrapper{
  height: 20% !important;
  width: 95% !important;
  border-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
}
.bg-lime{
  background-color: lime;
}
.select-modal{
  padding: 0px 20px;
}

.my-custom-class2 .modal-wrapper {
  height: 45%;
  margin-top: auto;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 4px 24px 0 rgb(138 129 124 / 10%) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.edit-table-circle{
  border: 1px solid #00365f;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 6px 5px 5px 5px;
  margin: auto;
  color: #00365f;
}
.edit-putts-circle{
  border: 1px solid #00365f;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 5px 5px 5px 5px;
  margin: auto;
  color: #00365f;
  font-size: 12px;
}
.s-28{
  font-size: 35px;
}

/* //admin */
.s-35{
  font-size: 35px;
}
.admin-cards{
  height: 71px;
  width: 90px;
}
.profile-cards {
  margin: 0;
  box-shadow: none;
}
.box-shadow {
  box-shadow: 0px 4px 3px rgb(0 0 0 / 9%);
}