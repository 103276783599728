/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@import url(https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700,800,900);

*:not(ion-icon) {
  font-family: 'Lato' !important;
}

.vh-8 {
  margin-top: 8vh;
}

.vh-10 {
  margin-top: 10vh;
}

.vh-12 {
  margin-top: 12vh;
}

.vh-15 {
  margin-top: 15vh;
}
.mt-0 {
  margin-top: -8px !important;
}
.mt-30 {
  margin-top: 30px;
}
.pointer {
  text-decoration: none;
  cursor: pointer;
}
.create-button-outline {
  border: 1px solid #00365f;
    border-radius: 5px;
    padding: 0px 3px 3px 3px;
}
.create-button-solid-yellow {
    border: 1px solid white;
    border-radius: 5px;
    padding: 0px 3px 3px 3px;
    color: #00365f;
    background-color:#ffd700;

}
.create-button-solid-white {
  border: 1px solid white;
  border-radius: 5px;
  padding: 0px 5px 5px 5px;
  color: #00365f;
  background-color:white;
}

.pro-shop-button {
  background: #fff;
  color: #00365f;
  border: 1px solid #00365f;
  font-weight: 600;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  width: 100%;
  margin: 5px;
}
.gold-background {
  background-color: #ffd700;
  background-image: linear-gradient(315deg, #b57c04 0%, #f6c254 74%);
  border-radius: 50%;
  padding: 2px 6px 2px 5px;
  color: #fff;
  font-size: 12px;
}
.silver-background {
  background-color: #c9c9c9;
  background-image: linear-gradient(315deg, #515151 0%, #dfdfdf 74%);
  border-radius: 50%;
  padding: 2px 6px 2px 5px;
  color: #fff;
  font-size: 12px;
}
.bronze-background {
  background-color: #a44200;
  background-image: linear-gradient(315deg, #7b3202 20%, #cc7704 74%);
  border-radius: 50%;
  padding: 2px 6px 2px 5px;
  color:#fff;
  font-size: 12px;
}
.vh-20 {
  margin-top: 20vh;
}

.vh-25 {
  margin-top: 25vh;
}

.vh-30 {
  margin-top: 30vh;
}

.vh-35 {
  margin-top: 35vh;
}

.vh-40 {
  margin-top: 40vh;
}

.vh-45 {
  margin-top: 45vh;
}

.vh-50 {
  margin-top: 50vh;
}
.w-100 {
  font-weight: 100;
}
.w-200 {
  font-weight: 200;
}
.w-300 {
  font-weight: 300;
}
.w-400 {
  font-weight: 400;
}
.w-500 {
  font-weight: 500;
}
.w-600 {
  font-weight: 600;
}
.w-700 {
  font-weight: 700;
}
.w-800 {
  font-weight: 800;
}
.pd-r-5 {
  padding-right: 5px;
}
.pd-l-5 {
  padding-left: 5px;
}
.pd-r-10 {
  padding-right: 10px;
}
.pd-l-10 {
  padding-left: 10px;
}
.slot-start-padding {
  padding: 5px 0px 0px 6px;
}
.slot-end-padding {
  padding: 2px 6px 0px 0px;
}
.color-blue {
  color: #00365f !important;
}

.color-red {
  color: #cb3737 !important;
}

.color-black {
  color: #000000 !important;
}

.color-gray {
  color: #bfbfbf !important;
}
.color-green {
  color: #00ac00;
}
.color-dark-gray {
  color: #626262 !important;
}

.bg-blue {
  background-color: #00365f !important;
  color: #FFF;
}

.bg-red {
  background-color: #cb3737 !important;
  color: #FFF;
}

.bg-black {
  background-color: #000000 !important;
  color: #000000;
}

.bg-gray {
  background-color: #e0e0e0 !important;
}
.blue-button {
  background: #00365f;
  padding: 10px 20px 10px 20px;
  color: #fff;
  border-radius: 25px;
}
.blue-anchor-button {
  text-decoration: none;
    cursor: pointer;
    background: #00365f;
    color: #fff;
    padding: 10px 15px 10px 15px;
    border-radius: 25px;
}
.popover-content.sc-ion-popover-ios {
  width: 97%;
  left: 10px;
}
ion-list-header {
  font-size: 16px;
}
.select-interface-option ion-label{
  font-size: 14px !important;
}
.events-card-options ion-row{
  float: right;
  font-size: 25px;
  padding-top: 5px;
}
.social-card {
  margin: 3px 3px 5px 3px;
}
.segment-toolbar {
  height: 45px;
    /* box-shadow: 0px 0px 6px rgb(0 0 0 / 19%); */
    --background: #fff;
}
.profile-toolbar {
  box-shadow: 0px 0px 6px rgb(0 0 0 / 19%);
  padding: 5px 5px 10px 5px;
  border: 1px solid #00365f;
  border-radius: 10px 10px 0px 0px;
}
.toolbar-top {
  height: 45px;
}
.button-border-20 {
  border-radius: 20px;
}
.ios-toolbar-top {
  padding-top: 45px;
  height: 90px;
  --background: #fff;
  /*box-shadow: 0px 4px 3px rgb(0 0 0 / 9%);*/
}
.dynamic-content-div > p {
  margin-top: 0;
}
.custom-input > input{
  padding-top: 8px !important;
}
.profile-link {
  cursor: pointer;
  color: #00365f;
}
.disabled-button {
  opacity: 0.5;
}
.bottom-menu-fab {
  margin-bottom: -60px;
}
.bottom-fab-list {
  display: flex;
  flex-direction: row
}
.fab-button-padding {
  margin: 0px 9px;
}
.back-fab-button {
  margin-bottom: 35px;
}
.input-margin {
  margin: 0px 17px;
  border: none;
}
.fab-icon {
  font-size: 25px;
  color: white;
}
.vh-5 {
  margin-top: 5vh;
}
.single-segment-toolbar {
  height: 65px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 19%);
}
.custom-profile-ion-range-slider{
  --bar-background: #cb3737;
    --bar-border-radius: 50px;
    --bar-height: 2px;
    --height: 15px;
    --knob-background: #00365f;
    --knob-border-radius: 50px;
    --knob-size: 15px;
}
.custom-ion-range-slider{
  --bar-background: #cb3737;
    --bar-border-radius: 50px;
    --bar-height: 2px;
    --height: 44px;
    --knob-background: #00365f;
    --knob-border-radius: 50px;
    --knob-size: 15px;
}
.range-disabled {
  opacity: 1;
}
::before {
  opacity: 0;
}
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #00365f;
  --ion-color-primary-rgb: 0, 54, 95;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003054;
  --ion-color-primary-tint: #1a4a6f;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --indicator-color: transparent;
  --indicator-height: 0;
  --ion-toolbar-segment-indicator-color: transparent;

}

@media (prefers-color-scheme: dark) {
  /*
   * Light Colors
   * -------------------------------------------
   */

  body {

  }

  /*
   * iOS Light Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Light Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
  }

  ion-segment{
    background: transparent;
    width: 99%;
    margin-top: 2px;
    margin-bottom: 2px;
    min-height:32px !important;
  }
  ion-segment-button {
    --color-checked: #cb3737;
    --color: #ffffff;
    font-weight: 550;
  }
  ion-tab-button {
    color: #00365f !important;
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

@media (prefers-color-scheme: light) {
  /*
   * Light Colors
   * -------------------------------------------
   */

  body {

  }

  /*
   * iOS Light Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Light Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #FFFFFF;
    --ion-background-color-rgb: 255,255,255;
  }

  ion-segment{
    background: #00365f;
    width: 99%;
    margin-top: 2px;
    margin-bottom: 2px;
    min-height:32px !important;
  }
  ion-segment-button {
    --color-checked: #cb3737;
    text-transform: capitalize;
    --color: #ffffff;
    font-weight: 550;
  }
  ion-tab-button {
    color: #00365f !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.tours-content{
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0px;
}
.content-height{
  max-height:50%
 }

@media only screen and (max-width:700px) {
  .content-height{
    max-height:40%
   }
}
/* Ipad Mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .content-height{
    max-height:70%
   }
}
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .content-height{
    max-height:70%
   }
}

/* Ipad Air */
@media only screen and (min-device-width: 820px) and (min-height: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
  .content-height{
    max-height:80% !important
   }
}

.score-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
}
.user-quota-align{
  width: 40%;
  margin :0px 10px
}
.user-driving-align{
  width: 60%;
}
.quota-text-align{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}
.userName-align{
  width: 100%;
  display: flex;
  justify-content: center;
}
.driving-distance-align{
  display: flex;
  justify-content: flex-end;
  margin:0px 15px;
}
.nsc-scores-list{
  display: contents !important;
  max-width: 100% !important;
}
.nsc-result{
  position: absolute !important;
  right:0 !important;
}
.won-tied-box{
  margin: 10px 5px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
.won-tied-header{
  background-color: rgb(239, 239, 239);
  width: 100%;
  height:37px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.won-tied-content{
  background-color: #fff;
  width: 100%;
  height:40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.won-tied-headings{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading{
  font-size: 12px;
  font-weight: 900;
}
.game-count{
  font-size: 14px;
}
.game-points{
  font-size: 14px;
  color:limegreen;
}
.scoring-record-box{
  margin: 10px 5px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  height: 300px;
  overflow-y: scroll;
}
.scoring-record-header{
  background-color: rgb(239, 239, 239);
  width: 100%;
  height:37px;
  display: flex;
  justify-content:center;
  align-items: center;
}
.week-header{
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 border-bottom:1px solid rgb(231, 230, 232) ;
}
.score-description{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  width: 56%;
}
.score-week-align{
  width: 44%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px;
}

.score-heading-color{
  color: #FF0000;
  font-size: 12px;
}
.week-heading-color{
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.playing-count{
  color:#000000;
  font-weight: 900;
  font-size: 14px;
}
.score-card-content-align{
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}
.match-heading{
  color: #FF0000;
}
.score-footer{
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin: 0px;
  padding: 3px 0px;
}
.my-custom-class {
    --height: 600px !important;
}
@media only screen and (max-width: 600px) {
  .my-custom-class {
    --height: 100% !important;
  }
  .scoring-record-box{
    height: 500px !important;
    overflow-y: scroll;
  }
}

.no-space{
  white-space: nowrap;
}
