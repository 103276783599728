ion-segment-button ion-label{
    font-weight: 550;
}
ion-modal ion-datetime {
    height: 382px;
}
.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    max-width: 300px;
}
.card-header{
    padding: 0px 0px 5px 0px;
}
.card-content{
    margin-top: 5px !important;
    padding: 0px 8px 0px 8px;
    font-size: 14px;
}
.user-details {
    margin-left: -10px;
}
.card-options {
    margin-left: 5px;
}
.card-options ion-row {
    float: right;
    font-size: 25px;
    padding-top: 5px;
}
.card-avatar{
    width: 40px;
    height: 40px;
}
.card-footer{
    padding: 10px 10px 10px 10px;
    border-top: 1px solid #d3d3d33b;
    justify-content: space-between;
}
.card-like-icon{
    width: 18px;
}
.card-delete-icon{
    width:20px;
}
.card-like-text{
    /* float: right;
    margin-top: 6%; */
    padding-left: 5px;
}
.card-comment-icon{
    width: 18px;
}
.card-comment{
    padding-left: 5px;
}
.edit-trash{
    color: rgb(185, 185, 185);
    margin-top: -7%;
}
.myclub-header{
    font-style: italic;
}

.main-fab {
    margin: 8px;
    height: 50px;
    width: 50px;
}

.add-tour-fab {
    margin: 8px -17px;
    height: 50px;
    width: 100px;
    --border-radius: 15px;
}

.compete-fab-list {
    flex-wrap: wrap-reverse;
    margin-left: -125px;
    align-items: flex-start;
}
.connect-fab-list {
    flex-wrap: wrap-reverse;
    margin-left: -50px;
    align-items: flex-start;
}
.create-post-fab{
    width: 80px;
    --border-radius: 14px !important;
    --background-activated: #cb3737;
    --background-focused: #cb3737;
    --background-hover: #cb3737;
}
.fab-button-in-list{
    --background-hover: #cb3737;
}
.about-fab{
    width: 80px;
    --border-radius: 14px !important;
}
.join-pack-fab{
    width: 110px;
    --border-radius: 14px !important;
}
.get-handicap-fab{
    width: 170px;
    --border-radius: 14px !important;
}
.share-tee-time{
    width: 100px;
    --border-radius: 14px !important;
}
.pack-post-fab{
    width: 120px;
    --border-radius: 14px !important;
}
.member-post-fab{
    width: 170px;
    --border-radius: 14px !important;
}
.community-post-fab{
    width: 160px;
    --border-radius: 14px !important;
}

.fs-29{
    font-size: 29px;
}
.fr{
    float: right;
}
.pt-9 {
    padding-top: 9px;
}
.pl-15 {
    padding-left: 15px;
}
.pd-5 {
    padding: 5px;
}
.packs-dropdown{
    padding: 5px 5px 0px 0px;
    color: black;
    margin-left: -5%;
    font-size: 16px;
}
.button-stay{
    height: 30px;
    width: 150px;
    border-radius: 9px;    
}
.button-delete{
    height: 30px;
    width: 150px;
    border-radius: 9px;    
}
.button-close{
    height: 30px;
    width: 100px;
    border-radius: 9px;    
}
.button-send{
    height: 40px;
    width: 150px;
    background-color: #18428e;
    border-radius: 5px;
}
.send-request{
    float: right;
    margin-right: 14px;
    margin-left: -10px;
}
.bg-orange{
    background-color: orange;
}
.tt-upper{
    text-transform: uppercase;
}
.wg-bold{
    font-weight: bold;
}
.select-pack-dropdown{
    border: 1px solid gray;
    width: 90%;
    margin: auto;
    border-radius: 7px;
}
.select-dropdown{
    border: 1px solid gray;
    width: 100%;
    margin: auto;
    border-radius: 7px;
}
.add-button{
    height: 40px;
    width: 67px;
    border-radius: 9px; 
}
.pad-t{
    padding: 15px 0px 0px 0px;
}
.bb-line{
    border-bottom: 1px solid lightgray;
}
.paypal-width{
    width: 70%;
    margin: auto;
}
.center {
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top:20vh;
}
.join-pack-modal .modal-wrapper {
    height: 70%;
    margin-top: auto;
    border-radius: 15px 15px 0px 0px;
}
.pending-scores-modal .modal-wrapper {
    height: 55%;
    margin-top: auto;
    border-radius: 15px 15px 0px 0px;
}
.quota-modal .modal-wrapper {
    height: 47%;
    margin-top: auto;
    border-radius: 15px 15px 0px 0px;
}
.close-bar{
    height: 18px;
}